import React, {
  FC, useEffect, useState,
} from 'react';
import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import {
  StackedBarChart,
  TableChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import PageTitle, { PageTitles } from '../../PageTitle';
import Header from './Header';
import useListWorkspaces from '../Workspaces/hooks/useListWorkspaces';
import { userAuthStore } from '../../../stores';
import { formatCurrency, getFirstDayOfWeek, listDates } from './utils';
import { useHasuraLazyQuery } from '../../../app/hooks/useHasuraQuery';
import {
  FullPageChartSection,
  getFormattedYAxisLabel,
  HalfPageChartSection,
} from '../../Radar/utils';
import InsightCard from '../../Home/InsightCard';
import ProgressBarList from '../../Home/ProgressBarList';
import { chartColourScale } from '../../Common/UserInsights/utils';
import ZeroStateUnlockAI from '../../Home/ZeroStateUnlockAI';

export interface DropdownItem {
  label: string,
  value: string,
}

export interface CostSavingsQueryResponseData {
  date: string,
  companyToken: string,
  workspaceUid: string,
  category: string,
  categoryGroup: string,
  eventName: string,
  eventDescription: string,
  totalTimeSaved: string,
  totalCostSaved: string,
}

interface CostSavingsQueryResponse { // eslint-disable-next-line camelcase
  cost_savings_calculator_per_workspace: CostSavingsQueryResponseData[],
}

export interface dataByTimestamp {
  [timestamp: string]: CostSavingsQueryResponseData,
}

interface numberIndexedByDate {
  [date: string]: number,
}

const CostSavingsQuery = gql`
  query costSavingsPerWorkspaceQuery ($days: Int!) {
    cost_savings_calculator_per_workspace(args: { days: $days }) {
      date: calendar_date
      companyToken: company_token
      workspaceUid: workspace_uid
      category
      categoryGroup: category_group
      eventName: event_name
      eventDescription: event_description
      totalTimeSaved: total_time_saved_seconds
      totalCostSaved: total_cost_saving_in_usd
    }
  }
`;

const timeFrameList = [
  { label: 'Last 7 days', value: '7' },
  { label: 'Last 14 days', value: '14' },
  { label: 'Last 30 days', value: '30' },
  { label: 'Last 6 months', value: '180' },
  { label: 'Last year', value: '365' },
];

const columns = [
  { field: 'category', header: 'Category', sortable: true },
  { field: 'categoryGroup', header: 'Category Group', sortable: true },
  {
    field: 'totalCostSaved',
    header: 'Savings Total',
    type: ColumnType.CURRENCY,
    sortable: true,
  },
];

const Insights: FC = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<DropdownItem>({ label: 'all', value: 'all' });
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<DropdownItem>({ label: 'Last 30 days', value: '30' });
  const [allCategoryGroups, setAllCategoryGroups] = useState<string[]>([]);
  const [costSavingsData, setCostSavingsData] = useState<CostSavingsQueryResponseData[]>([]);
  const [errorHasNoData, setErrorHasNoData] = useState(false);

  const { joinedWorkspacesList } = useListWorkspaces();

  const { companyName } = userAuthStore((s) => s.currentUser);

  const [getCostData, { loading: isLoadingData }] = useHasuraLazyQuery<CostSavingsQueryResponse>({
    query: CostSavingsQuery,
    variables: { days: parseInt(selectedTimeFrame.value, 10) },
    onCompleted: ({ data }) => {
      if (data.cost_savings_calculator_per_workspace?.length > 0) {
        setErrorHasNoData(false);
        setCostSavingsData(data.cost_savings_calculator_per_workspace);

        const categoryGroups = Array.from(new Set(
          data.cost_savings_calculator_per_workspace.map(({ categoryGroup }) => categoryGroup),
        ));
        setAllCategoryGroups(categoryGroups);
      } else {
        setErrorHasNoData(true);
      }
    },
  });

  useEffect(() => {
    getCostData();
  }, [selectedTimeFrame]);

  const filterToSelectedWorkspaces = () => (
    costSavingsData
      .filter(({ workspaceUid }) => {
        if (selectedWorkspace.value === 'all') {
          return true;
        }
        return workspaceUid === selectedWorkspace.value;
      })
  );

  const getWeekStartDate = (date: string) => {
    const { weekNumber, year } = DateTime.fromFormat(date, 'yyyy-LL-dd');
    return getFirstDayOfWeek(year, weekNumber).toFormat('yyyy-LL-dd');
  };

  const getTotalSaved = () => {
    const onlyDesiredWorkspaces = filterToSelectedWorkspaces();

    const totalSaved = onlyDesiredWorkspaces
      .reduce((currentObject, { date, totalCostSaved }) => {
        const clonedObject = { ...currentObject };

        // Group by week
        const weekStartDate = getWeekStartDate(date);

        clonedObject[weekStartDate] = (clonedObject[weekStartDate] || 0) + Number(totalCostSaved);
        return clonedObject;
      }, {} as numberIndexedByDate);

    const listOfDates = listDates({ daysAgo: Number(selectedTimeFrame.value), groupByWeek: true });

    const totalSpentOnSelectedDays = listOfDates
      .reduce((currentTotal, date) => currentTotal + (totalSaved[date] || 0), 0);

    return totalSpentOnSelectedDays;
  };

  const getTotalSavedPerCategory = () => {
    const onlyDesiredWorkspaces = filterToSelectedWorkspaces();

    const listOfDates = listDates({ daysAgo: Number(selectedTimeFrame.value), groupByWeek: false });

    const dataByCategory = allCategoryGroups.map((category) => (
      onlyDesiredWorkspaces
        .filter(({ categoryGroup }) => category === categoryGroup)
        .filter(({ date }) => listOfDates.includes(date))
        .reduce((currentObject, { totalCostSaved }) => ({
          categoryGroup: category,
          totalCostSaved: (currentObject?.totalCostSaved || 0) + Number(totalCostSaved),
        }), {} as { categoryGroup: string, totalCostSaved: number })
    ));

    const withoutEmptyEntries = dataByCategory
      .filter((entry) => Object.keys(entry).length !== 0);

    return withoutEmptyEntries;
  };

  const getStackedCategoryAmountPerWeek = () => {
    const onlyDesiredWorkspaces = filterToSelectedWorkspaces();

    const dataByCategory = allCategoryGroups.map((category) => (
      onlyDesiredWorkspaces
        .filter(({ categoryGroup }) => category === categoryGroup)
        .reduce((currentObject, { date, totalCostSaved }) => {
          const clonedObject = { ...currentObject };

          // Group by week
          const weekStartDate = getWeekStartDate(date);

          clonedObject[weekStartDate] = (clonedObject[weekStartDate] || 0) + Number(totalCostSaved);
          return clonedObject;
        }, {} as numberIndexedByDate)
    ));

    const listOfDates = listDates({ daysAgo: Number(selectedTimeFrame.value), groupByWeek: true });

    const dataByDate = dataByCategory.map((categoryData) => (
      listOfDates.reduce((currentObject, date) => {
        const clonedObject = { ...currentObject };
        clonedObject[date] = categoryData[date] || 0;
        return clonedObject;
      }, {} as numberIndexedByDate)
    ));

    const dataForChart = dataByDate
      .map((dataItem, index) => (
        Object.entries(dataItem).map(([date, amountSaved]) => ({
          x: DateTime.fromFormat(date, 'yyyy-LL-dd').toFormat('dd LLL'),
          y: amountSaved,
          isoTimestamp: DateTime.fromFormat(date, 'yyyy-LL-dd').toISO(),
          categoryName: allCategoryGroups[index],
        }))
      ));

    const isEmptyData = dataForChart
      .every((category) => (
        category.every(({ y }) => y === 0)
      ));

    return isEmptyData ? [] : dataForChart;
  };

  const getAmountSavedPerEveryCategoryAndCategoryGroup = () => {
    const onlyDesiredWorkspaces = filterToSelectedWorkspaces();

    const listOfDates = listDates({ daysAgo: Number(selectedTimeFrame.value), groupByWeek: false });

    const dataByCategory = allCategoryGroups.map((categoryFromList) => (
      onlyDesiredWorkspaces
        .filter(({ categoryGroup }) => categoryGroup === categoryFromList)
        .filter(({ date }) => listOfDates.includes(date))
        .reduce((currentObject, { category, categoryGroup, totalCostSaved }) => ({
          category,
          categoryGroup,
          totalCostSaved: (currentObject?.totalCostSaved || 0) + Number(totalCostSaved),
        }), {} as { category: string, categoryGroup: string, totalCostSaved: number })
    ));

    const withoutEmptyEntries = dataByCategory
      .filter((entry) => Object.keys(entry).length !== 0);

    return withoutEmptyEntries;
  };

  return (
    <>
      <PageTitle title={PageTitles.INSIGHTS} />
      <AutoLayout
        direction="vertical"
        padding="very-dense"
        verticalGap="expanded"
        distribution="packed"
        alignment="top-left"
        width="extra-large"
      >
        <Header
          selectedWorkspace={selectedWorkspace}
          workspaceList={[{ name: 'all', uid: 'all' }, ...joinedWorkspacesList].map(
            ({ name, uid }) => ({ label: name, value: uid }),
          )}
          onWorkspaceChanged={setSelectedWorkspace}
          selectedTimeFrame={selectedTimeFrame}
          timeFrameList={timeFrameList}
          onTimeFrameChanged={setSelectedTimeFrame}
          loading={isLoadingData}
        />
        {isLoadingData && <div style={{ marginTop: '100px' }}><Spinner thin /></div>}
        {!isLoadingData && errorHasNoData && <ZeroStateUnlockAI contentType="zero_state_cta" pageUrl="/insights" />}
        {!isLoadingData && !errorHasNoData && (
          <div style={{
            display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
          }}
          >
            <HalfPageChartSection height="fit-content">
              <InsightCard
                title="Overview"
                cardBackgroundColor="var(--indigo0)"
                borderColor="var(--indigo10)"
                icon="time"
                iconColor="var(--indigo60)"
                iconBackgroundColor="var(--indigo10)"
                titleColor="primary_alt"
                isLoading={isLoadingData}
              >
                <AutoLayout
                  direction="vertical"
                  padding="none"
                  verticalGap="very-dense"
                  distribution="packed"
                  alignment="top"
                >
                  <Typography type="body-bold-small" colorStep="80">
                    {`This is how much ${companyName ? `${companyName}` : 'you'} saved in the ${selectedTimeFrame.label.toLowerCase()} by running on paradime`}
                  </Typography>
                  <Typography
                    type="h4"
                    colorStep="80"
                  >
                    {`${formatCurrency(getTotalSaved())}  🎉 `}
                  </Typography>
                </AutoLayout>
              </InsightCard>
            </HalfPageChartSection>

            <HalfPageChartSection height="fit-content">
              <InsightCard
                title="Cost Saved Breakdown"
                cardBackgroundColor="var(--green0)"
                borderColor="var(--green10)"
                icon="series-search"
                iconColor="var(--green60)"
                iconBackgroundColor="var(--green5)"
                titleColor="success"
                isLoading={isLoadingData}
              >
                <ProgressBarList
                  progressBars={getTotalSavedPerCategory()
                    .sort((a, b) => {
                      if (a.totalCostSaved === b.totalCostSaved) return 0;
                      return a.totalCostSaved < b.totalCostSaved ? 1 : -1;
                    })
                    .map(({ categoryGroup, totalCostSaved }) => ({
                      title: categoryGroup,
                      amount: totalCostSaved / getTotalSaved(),
                      label: formatCurrency(totalCostSaved, true),
                    }))}
                />
              </InsightCard>
            </HalfPageChartSection>

            <Divider
              style={{
                margin: '16px 0',
                width: 'calc(100% - 16px)',
              }}
            />

            <FullPageChartSection height="400px">
              <StackedBarChart
                title="Weekly Cost Savings by Category"
                hasNoData={getStackedCategoryAmountPerWeek().length === 0}
                data={getStackedCategoryAmountPerWeek()}
                xAxisLabels={listDates({
                  daysAgo: Number(selectedTimeFrame.value),
                  groupByWeek: true,
                  dateFormatterString: 'dd LLL',
                })}
                yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
                legendLabels={allCategoryGroups}
                xLabel="Calendar date"
                yLabel="Amount saved in $"
                xAxisDomain={[
                  0.5,
                  listDates({
                    daysAgo: Number(selectedTimeFrame.value), groupByWeek: true,
                  }).length + 1,
                ]}
                isLoading={isLoadingData}
                tooltipLabelFormatter={{
                  yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
                }}
                customColourScale={chartColourScale}
              />
            </FullPageChartSection>

            <FullPageChartSection height="max-content">
              <TableChart
                title="Cost Savings Breakdown details"
                hasNoData={getAmountSavedPerEveryCategoryAndCategoryGroup().length === 0}
                columns={columns}
                data={getAmountSavedPerEveryCategoryAndCategoryGroup()}
                size="small"
                showSearchBar={false}
                usePagination
                rowsPerPage={10}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </FullPageChartSection>
          </div>
        )}
      </AutoLayout>
    </>
  );
};

export default Insights;

/* eslint-disable no-underscore-dangle */
import React, {
  FC, Suspense, lazy, useState, ComponentType, ReactNode,
  useRef,
  useEffect,
} from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Location } from 'history';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import TaskManagerCallback from '../components/Common/TaskManagerCallback';
import {
  alertStore,
  appStore, userAuthStore,
} from '../stores';
import InviteUsers from '../components/Common/InviteUsers';
import SlackSuccess from '../components/Common/InviteUsers/SlackSuccess';
import PageAnalytics from '../components/Common/Libraries/Segment/PageAnalytics';
import UnhandledError from '../components/Common/Error/UnhandledError';
import BlockedLogin from '../components/Common/Error/BlockedLogin';
import AppBlockedLogout from '../components/Common/Error/AppBlockedLogout';
import Loom from '../components/Common/Libraries/Loom';
import ExtensionOnboardingSuccess from '../components/Onboarding/Extension/Success';
import ExtensionLoginSuccess from '../components/Start/Extension/LoginSuccess';
import UserHasNoCompany from '../components/Start/UserHasNoCompany';
import { EditorUrlContext } from '../components/Editor/hooks/useSetFirstEditor';
import SnowflakeOAuthCallback from '../components/Common/Libraries/Auth0/SnowflakeOAuthCallback';
import BigqueryOAuthCallback from '../components/Common/Libraries/Auth0/BigqueryOAuthCallback';
import Lineage from '../components/Lineage';
import GithubOauthCallback from '../components/Common/Libraries/GitHub/GithubOauthCallback';
import AuthSuccess from '../components/Start/AuthSuccess';
import EnterOrg from '../components/Start/ParadimeOrganisations/EnterOrg';
import LandingPage from '../components/Start/LandingPage';
import GlobalActions from './GlobalActions';
import useNonAuthMemory from '../components/hooks/NonAuthMemory';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../components/Onboarding';
import ErrorMainAppOrgAlreadyExists from '../components/Onboarding/MainApp/CreateNewCompany/ErrorOrgAlreadyExists';
import Editor from '../components/Editor';
import { userHasEditorAccess } from '../utilis/PermissionsService';
import AppPage from '../components/Common/Layouts/AppPage';
import CompanycreationInProgress from '../components/Common/CompanyCreationInProgress';
import AccountLockedDialog from '../components/Common/AccountLockedDialog';
import ToastMessage from '../utilis/ToastMessage';

// Lazy-imported routes
const AccountSettings = lazy(() => import('../components/AccountSettings'));
const ProfileSettings = lazy(() => import('../components/ProfileSettings'));
const Platform = lazy(() => import('../components/Platform'));
const DataCatalog = lazy(() => import('../components/Catalog'));
const Onboarding = lazy(() => import('../components/Onboarding'));
const Home = lazy(() => import('../components/Home'));
const Bolt = lazy(() => import('../components/Bolt'));
const Radar = lazy(() => import('../components/Radar'));
const LinkGithub = lazy(() => import('../components/Start/LinkGithub'));
const CreateWorkspace = lazy(() => import('../components/Platform/Workspaces/CreateWorkspace'));
const ExpiredInvite = lazy(() => import('../components/Common/Error/ExpiredInvite'));

const PathsWithNoNavBar = [
  'signup',
  'onboarding',
  'extension-onboarding-success',
  'extension-login-success',
  'auth',
  'auth-success',
  'link-github',
  'blocked',
  'not-on-our-watch',
  'invite-expired',
  'account-blocked',
];
const FullPathsWithNoNavBar = [
  '/github/oauth_callback',
];

const computeCurrentApp = (location: Location) => {
  let [, rawAppName] = location.pathname.split('/');

  /** special app names */
  if (rawAppName === 'catalog') rawAppName = 'data-catalog';
  if (rawAppName === 'editor') rawAppName = 'code-editor';

  const spacedAppName = rawAppName.replace(/-/g, ' ');
  // capitalize first letter of each word
  const capitalizedAppName = spacedAppName.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());

  return capitalizedAppName;
};

interface OrgDependantRouteProps {
  exact?: boolean,
  path: string,
  component?: ComponentType,
  requiresOrg?: boolean,
  children?: ReactNode,
}

const AppWithSwitch: FC<{
  userHasCompany: boolean, userHasOrganisation: boolean,
}> = ({ userHasCompany, userHasOrganisation }) => {
  const setToaster = alertStore((s) => s.setToaster);
  const showInviteUser = appStore((s) => s.showInviteUser);
  const { accessLevel } = userAuthStore((s) => s.currentUser);

  const location = useLocation();
  const [editorUrl, setEditorUrl] = useState('');
  const [isEditorReady, setIsEditorReady] = useState(false);

  const toastRef = useRef<Toast>(null);

  useEffect(() => {
    setToaster(toastRef);
  }, []);

  useNonAuthMemory();

  const checkIfNavBarShouldBeDisplayed = () => {
    if (!userHasCompany || !userHasOrganisation) return false;

    const [, firstPath] = location.pathname.split('/');

    /** path is not included on excluded list and is not '/' */
    return (
      !PathsWithNoNavBar.includes(firstPath)
        && firstPath !== ''
        && !FullPathsWithNoNavBar.includes(location.pathname)
    );
  };

  const OrgDependantRoute = ({
    exact,
    path,
    component,
    requiresOrg = true,
    children,
  }: OrgDependantRouteProps) => {
    if (requiresOrg && userHasOrganisation) {
      return (
        <Route exact={exact} path={path} component={component}>
          {children}
        </Route>
      );
    }

    if (!requiresOrg) {
      return <Route exact={exact} path={path} component={component} />;
    }
    return null;
  };

  return (
    <AppPage
      isNavBarVisible={checkIfNavBarShouldBeDisplayed()}
      currentApp={computeCurrentApp(location)}
    >
      <PageAnalytics />
      {userHasOrganisation && (
        <GlobalActions
          userHasCompany={userHasCompany}
          setIsEditorReady={setIsEditorReady}
        />
      )}
      <Toast
        ref={toastRef}
        content={(props) => (
          <ToastMessage
            // @ts-ignore - PrimeReact's types for the props are just wrong
            type={props.message.severity}
            header={props.message.summary}
            message={props.message.detail}
          />
        )}
      />
      <InviteUsers show={showInviteUser} />
      <EditorUrlContext.Provider value={{ url: editorUrl, setUrl: setEditorUrl }}>
        {userHasOrganisation
          && userHasEditorAccess(accessLevel)
          && isEditorReady
          && !window.location.pathname.includes('onboarding')
          && (
            <div hidden={window.location.pathname !== '/editor'} style={{ width: '100%', height: '100%' }}>
              <Editor />
            </div>
          )}
        <CompanycreationInProgress />
        <AccountLockedDialog />
        <Switch>
          <Suspense fallback={<Loader />}>
            <OrgDependantRoute exact path="/account-blocked" component={AppBlockedLogout} />
            <OrgDependantRoute exact path="/blocked" component={BlockedLogin} requiresOrg={false} />
            <OrgDependantRoute exact path="/not-on-our-watch" component={BlockedLogin} requiresOrg={false} />
            <OrgDependantRoute exact path="/enter-organisation" component={EnterOrg} requiresOrg={false} />
            <OrgDependantRoute exact path="/invite-expired" component={ExpiredInvite} requiresOrg={false} />
            <OrgDependantRoute
              exact
              path={`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.ERROR_ORG_ALREADY_EXISTS}`}
              component={ErrorMainAppOrgAlreadyExists}
              requiresOrg={false}
            />
            <OrgDependantRoute exact path="/" component={LandingPage} requiresOrg={false} />
            <OrgDependantRoute exact path="/onboarding/:pageRef" component={Onboarding} />
            <OrgDependantRoute exact path="/home">
              <Home isEditorReady={isEditorReady} />
            </OrgDependantRoute>
            <OrgDependantRoute exact path="/error" component={UnhandledError} />
            <OrgDependantRoute exact path="/auth-success">
              <AuthSuccess />
            </OrgDependantRoute>
            <OrgDependantRoute exact path="/link-github" component={LinkGithub} requiresOrg={false} />
            <OrgDependantRoute exact path="/auth/slack-success" component={SlackSuccess} />
            <OrgDependantRoute exact path="/account-settings/:id" component={AccountSettings} />
            <OrgDependantRoute exact path="/profile-settings/:id" component={ProfileSettings} />
            <OrgDependantRoute exact path="/lineage/home">
              <Lineage initialTab="search" currentPathname={location.pathname} />
            </OrgDependantRoute>
            <OrgDependantRoute exact path="/lineage/:id/:node1Hash/:node2Hash">
              <Lineage initialTab="search" currentPathname={location.pathname} />
            </OrgDependantRoute>
            <OrgDependantRoute path="/bolt" component={Bolt} />
            <OrgDependantRoute exact path="/radar/:pageId" component={Radar} />
            <OrgDependantRoute exact path="/radar/:pageId/:tabId" component={Radar} />
            <OrgDependantRoute path="/catalog/:id" component={DataCatalog} />
            <OrgDependantRoute exact path="/catalog">
              <Redirect to="/catalog/search" />
            </OrgDependantRoute>
            <OrgDependantRoute exact path="/makeALoom" component={Loom} />
            <OrgDependantRoute exact path="/extension-onboarding-success" component={ExtensionOnboardingSuccess} />
            <OrgDependantRoute exact path="/extension-login-success" component={ExtensionLoginSuccess} />
            <OrgDependantRoute exact path="/no-company" component={UserHasNoCompany} />
            <OrgDependantRoute exact path="/snowflake/oauth_callback" component={SnowflakeOAuthCallback} />
            <OrgDependantRoute exact path="/bigquery/oauth_callback" component={BigqueryOAuthCallback} />
            <OrgDependantRoute exact path="/github/oauth_callback" component={GithubOauthCallback} />
            <OrgDependantRoute exact path="/auth/task-manager-success" component={TaskManagerCallback} />
            <OrgDependantRoute exact path="/create-workspace/:pageRef" component={CreateWorkspace} />
            <OrgDependantRoute exact path="/platform/:id" component={Platform} />
          </Suspense>
        </Switch>
      </EditorUrlContext.Provider>
    </AppPage>
  );
};

export default AppWithSwitch;

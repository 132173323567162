import { gql } from '@apollo/client';

gql.disableFragmentWarnings();

export namespace EnvName {
  export const PRODUCTION = 'production';
  export const DEVELOPMENT = undefined;
}

export const getBoltRunSummary = gql`
  query AiExplainDbtCommandOutput($scheduleCommandId: Int!) {
    aiExplainDbtCommandOutput(scheduleCommandId: $scheduleCommandId) {
        ok
        message
        traceId
    }
  }
`;

export const getAlertConfig = gql`
  query GetAlertConfig {
    getAlertConfig {
        ok
        configs {
            alertId
            name
            category
            enabled
            threshold
            description
        }
    }
  }
`;

export const setAlertConfig = gql`
  mutation SetAlertConfig(
    $alertId: String!,
    $enabled: Boolean!,
    $threshold: Float!
  ) {
    setAlertConfig(alertId: $alertId, enabled: $enabled, threshold: $threshold) {
        ok
    }
  }
`;

export const createTicket = gql`
  mutation CreateMergeTicket(
    $assignees: [String!]!,
    $collection: String!,
    $creator: String!,
    $description: String!,
    $ticketContext: String!,
    $ticketContextId: String!,
    $ticketType: String!,
    $title: String!,
  ) {
    createMergeTicket(
        assignees: $assignees
        collection: $collection
        creator: $creator
        description: $description
        ticketContext: $ticketContext
        ticketContextId: $ticketContextId
        ticketType: $ticketType
        title: $title
    ) {
        ok
        errorLog
        ticketUrl
    }
  }
`;

export const getTicketCollections = gql`
  query GetMergeTicketCollections {
    getMergeTicketCollections {
        ok
        collections {
            id
            name
        }
    }
  }
`;

export const getTicketUsers = gql`
  query GetMergeTicketUsers {
    getMergeTicketUsers {
        ok
        users {
            id
            user
            email
        }
    }
  }
`;

export const getTicket = gql`
  query GetMergeTicket(
    $ticketContext: String!,
    $ticketContextId: String!,
  ) {
    getMergeTicket(ticketContext: $ticketContext, ticketContextId: $ticketContextId) {
        ok
        found
        mergeTicketId
        mergeTicketUrl
        integrationIsConnected
        integrationName
    }
  }
`;

export const generateMergeLink = gql`
  query GetMergeLinkToken($category: String!, $integration: String!) {
    getMergeLinkToken(category: $category, integration: $integration) {
        linkUrl
        linkToken
        isConnected
        categoryIsConnected
    }
  }
`;

export const setMergeAccountToken = gql`
  mutation SetMergeAccountToken($category: String!, $integration: String!, $publicToken: String!) {
    setMergeAccountToken(
        category: $category
        integration: $integration
        publicToken: $publicToken
    ) {
        ok
        errorLog
    }
  }
`;

export const triggerScheduleParse = gql`
  mutation TriggerScheduleParse {
    triggerScheduleParse {
        ok
    }
  }
`;

export const removeMergeIntegration = gql`
  mutation RemoveMergeIntegration($category: String!, $integration: String!) {
    removeMergeIntegration(category: $category, integration: $integration) {
        ok
    }
  }
`;

export const getScheduleParse = gql`
query getScheduleParse($offset: Int!, $limit: Int!, $showInactive: Boolean!) {
  getScheduleNames(offset: $offset, limit: $limit, showInactive: $showInactive) {
    ok
    globalError
    lastParse
    isParsing
  }
}
`;

export const getKnockUser = gql`
  query GetKnockUser {
    getKnockUser {
        userToken
        userId
        publicApiKey
        channelId
        toastChannelId
        tenantId
        subscriptionIds
    }
  }
`;

export const billingDetails = gql`
  query billingDetails {
    billingDetails{
      ok
      subscriptionsDataB64
      failureReason
    }
  }
`;

export const productDetails = gql`
  query productDetails {
    productDetails{
      ok
      productsDataB64
      failureReason
    }
  }
`;

export const upcomingInvoicePlanChange = gql`
  query upcomingInvoicePlanChange($newPriceId: String!){
    upcomingInvoicePlanChange(newPriceId: $newPriceId){
      ok
      failureReason
      isPricedPerUser
      trialEnd
      upcomingInvoicePlanChangeData {
        unitLabel
        invoiceLinesData {
          description
          quantity
          interval
          amount
        }
        amountDue
      }
    }
  }
`;

export const generateStripeCustomerPortalUrl = gql`
  mutation generateStripeCustomerPortalURL($returnUrl: String!) {
    generateStripeCustomerPortalUrl(returnUrl: $returnUrl){
      ok
      stripeCustomerPortalUrl
    }
  }
`;

export const updateStripePlan = gql`
  mutation updateStripePlan($newPriceId: String!, $paymentMethodId: String!) {
    updateStripePlan(newPriceId: $newPriceId, paymentMethodId: $paymentMethodId){
      ok
    }
  }
`;

export const isCurrentBranchClean = gql`
  query isCurrentBranchClean{
    isCurrentBranchClean{
      ok
      isClean
    }
  }
`;

export const getGitBranchInfo = gql`
  query getGitBranchInfo($importedOnly: Boolean){
    getGitBranchInfo(importedOnly: $importedOnly){
      ok
      branches{
        name
        commitHash
      }
      mainBranch{
        name
        commitHash
      }
      currentBranch{
        name
        commitHash
      }
      remoteMainBranch{
        name
        commitHash
    }
    }
  }
`;

export const getGitCurrentAndMainBranchInfo = gql`
  query getGitCurrentAndMainBranchInfo{
    getGitCurrentAndMainBranchInfo{
      ok
      mainBranch{
          name
          commitHash
      }
      remoteMainBranch{
          name
          commitHash
      }
      currentBranch{
          name
          commitHash
      }
  }
  }
`;

export const getGqlParadimeAccountTypes = gql`
  query getGqlParadimeAccountTypes{
    userIdentity{
      accessLevels
      inviteLevels
      role
    }
  }
`;
export const getProfile = gql`
  fragment userInformation on GQLUserInformation {
    uid
    name
    email
    avatarUrl
    role
    team
    companyName
    companyToken
    companyRegion
    accessLevels
    accessLevel
    inviteLevels
    isFirstAdmin
    isSandboxWorkspace
    intercomHmac
    workspaceUid
    workspaceName
    hasura {
      endpoint
      token
    }
    isSandboxWorkspace
  }

  query getProfile{
    userIdentity{
      userInformation {
        ...userInformation
      }
    }
  }
`;

export const addUser = gql`
  mutation addUser($userToken: String!){
    addUser(userToken: $userToken){
      ok
      errorIsAlreadyOnboarded
      errorTokenExpired
      isBasicSetupIncomplete
      isGitSetupIncomplete
      isDwhSetupIncomplete
      userInformation {
        ...userInformation
      }
    }
  }
`;

export const changeUserInfo = gql`
  mutation changeUserInfo($roleName: String!, $teamPurpose: String!, $dbtLevel: String) {
    changeUserInfo(
      roleName: $roleName,
      teamPurpose: $teamPurpose,
      dbtLevel: $dbtLevel
    ) {
      ok
    }
  }
`;

export const inviteViaSlack = gql`
mutation inviteViaSlack($users: [InviteViaSlackObject!]!){
  inviteViaSlack(
    users: $users
  ){ok}
}
`;

export const inviteViaEmail = gql`
mutation inviteViaEmail($users: [InviteViaEMailObject!]!){
  inviteViaEmail(
    users: $users
  ){ok}
}
`;

export const setDbtVersion = gql`
  mutation setDbtVersion($dbtVersion: String!){
    setDbtVersion(dbtVersion: $dbtVersion){
      ok
    }
  }
`;

export const checkGit = gql`
  query checkGit($uri: String!, $gitProvider: String!){
    checkGitUri(uri: $uri, gitProvider: $gitProvider){
      ok
    }
  }
`;

export const companyOnboardingSetGitUri = gql`
  mutation companyOnboardingSetGitUri(
    $dbtProjectDir: String,
    $uri: String!,
    $gitProvider: String!,
  ){
    companyOnboardingSetGitUri(
      dbtProjectDir: $dbtProjectDir,
      uri: $uri,
      gitProvider: $gitProvider,
    ){
      ok
    }
  }
`;

export const regenerateSshKey = gql`
  mutation regenerateSshKey{
    regenerateSshKey{
      ok
      publicKey
    }
  }
`;

export const slackSearch = gql`
  query slackSearch($query: String!){
    slackSearch(query: $query){
      ok
      users {
        slackId
        userName
        profileRealName
        profileRealNameNormalized
        profileDisplayName
        profileDisplayNameNormalized
        profileImage32
      }
    }
  }
`;

export const wd = gql`
  query checkProfileAddSnowflake(
        $username: String!,
        $password: String,
        $schema: String!,
        $credentialId: String!,
        $threads: Int!,
        $role: String!,
        $database: String!,
        $warehouse: String!,
        $privateKey: String,
        $privateKeyPassphrase: String,
        $profile: String!,
    ){
      checkProfileAddSnowflake(
        username: $username
        password: $password
        schema: $schema
        credentialId: $credentialId
        threads: $threads
        role: $role
        database: $database
        warehouse: $warehouse
        privateKey: $privateKey
        privateKeyPassphrase: $privateKeyPassphrase
        profile: $profile
      ){ok error}
    }
`;

export const profileAddSnowflake = gql`
  mutation profileAddSnowflake(
      $username: String!,
      $password: String,
      $schema: String!,
      $credentialId: String!,
      $threads: Int!,
      $database: String!,
      $role: String!,
      $warehouse: String!
      $privateKey: String,
      $privateKeyPassphrase: String,
      $profile: String!
  ){
    profileAddSnowflake(
      username: $username
      password: $password
      schema: $schema
      credentialId: $credentialId
      threads: $threads
      database: $database,
      role: $role,
      warehouse: $warehouse
      privateKey: $privateKey
      privateKeyPassphrase: $privateKeyPassphrase
      profile: $profile
    ){
      ok,
      credentialId,
    }
  }
`;

export const checkProfileAddRedshift = gql`
  query checkProfileAddRedshift(
      $username: String!,
      $password: String!,
      $schema: String!,
      $credentialId: String!,
      $threads: Int!,
      $database: String!,
      $profile: String!
    ){
      checkProfileAddRedshift(
        username: $username
        password: $password
        schema: $schema
        credentialId: $credentialId
        threads: $threads
        database: $database
        profile: $profile
      ){ok error}
    }
`;

export const profileAddRedshift = gql`
  mutation profileAddRedshift(
      $username: String!,
      $password: String!,
      $schema: String!,
      $credentialId: String!,
      $threads: Int!,
      $database: String!,
      $profile: String!
  ){
    profileAddRedshift(
      username: $username
      password: $password
      schema: $schema
      credentialId: $credentialId
      threads: $threads
      database: $database
      profile: $profile
    ){ok}
  }
`;

export const checkProfileAddBigquery = gql`
  query checkProfileAddBigquery($datasetName: String!, $credentialId: String!, $threads: Int!, $profile: String!){
      checkProfileAddBigquery(
        datasetName: $datasetName
        credentialId: $credentialId
        threads: $threads
        profile: $profile
      ){ok error}
    }
`;

export const profileAddBigquery = gql`
  mutation profileAddBigquery($datasetName: String!, $credentialId: String!, $threads: Int!, $profile: String!){
    profileAddBigquery(
      datasetName: $datasetName
      credentialId: $credentialId
      threads: $threads
      profile: $profile
    ){
      ok
      credentialId
    }
  }
`;

export const dwhCheckSnowflake = gql`
  query dwhCheckSnowflake(
      $connectionName: String!,
      $database: String!,
      $password: String,
      $schema: String!,
      $targetName: String!,
      $threads: Int!,
      $username: String!,
      $account: String!,
      $role: String!,
      $warehouse: String!,
      $useSso: Boolean,
      $clientId: String,
      $clientSecret: String,
      $credentialId: String!,
      $useKeyPairAuth: Boolean,
      $privateKey: String,
      $privateKeyPassphrase: String,
      $profile: String
    ){
      dwhCheckSnowflake(
        connectionName: $connectionName
        database: $database
        password: $password
        schema: $schema
        targetName: $targetName
        threads: $threads
        username: $username
        account: $account
        role: $role
        warehouse: $warehouse
        useSso: $useSso
        clientId: $clientId
        clientSecret: $clientSecret
        credentialId: $credentialId
        useKeyPairAuth: $useKeyPairAuth
        privateKey: $privateKey
        privateKeyPassphrase: $privateKeyPassphrase
        profile: $profile
      ){
        ok
        error
      }
    }
`;

export const setupDwhProductionSnowflake = gql`
  mutation setupDwhProductionSnowflake(
    $connectionName: String!,
    $database: String!,
    $password: String,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
    $username: String!,
    $account: String!,
    $role: String!,
    $warehouse: String!,
    $productionEnvName: String!,
    $credentialId: String,
    $useSso: Boolean,
    $useKeyPairAuth: Boolean,
    $privateKey: String,
    $privateKeyPassphrase: String,
    $profile: String
  ){
    setupDwhProductionSnowflake(
      connectionName: $connectionName
      database: $database
      password: $password
      schema: $schema
      targetName: $targetName
      threads: $threads
      username: $username
      account: $account
      role: $role
      warehouse: $warehouse
      productionEnvName: $productionEnvName
      credentialId: $credentialId
      useSso: $useSso
      useKeyPairAuth: $useKeyPairAuth
      privateKey: $privateKey
      privateKeyPassphrase: $privateKeyPassphrase
      profile: $profile
    ){
      ok
    }
  }
`;

export const setupDwhSnowflake = gql`
  mutation setupDwhSnowflake(
    $connectionName: String!,
    $database: String!,
    $password: String,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
    $username: String!,
    $account: String!,
    $role: String!,
    $warehouse: String!,
    $credentialId: String,
    $useSso: Boolean,
    $clientId: String,
    $clientSecret: String,
    $useKeyPairAuth: Boolean,
    $privateKey: String,
    $privateKeyPassphrase: String,
    $profile: String
  ){
    setupDwhSnowflake(
      connectionName: $connectionName
      database: $database
      password: $password
      schema: $schema
      targetName: $targetName
      threads: $threads
      username: $username
      account: $account
      role: $role
      warehouse: $warehouse
      credentialId: $credentialId
      useSso: $useSso
      clientId: $clientId
      clientSecret: $clientSecret
      useKeyPairAuth: $useKeyPairAuth
      privateKey: $privateKey
      privateKeyPassphrase: $privateKeyPassphrase
      profile: $profile
    ){
      ok,
      credentialId
    }
  }
`;

export const companyOnboardingSnowflake = gql`
  mutation companyOnboardingSnowflake(
    $connectionName: String!,
    $database: String!,
    $password: String,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
    $username: String!,
    $account: String!,
    $role: String!,
    $warehouse: String!,
    $useSso: Boolean,
    $clientId: String,
    $clientSecret: String,
    $useKeyPairAuth: Boolean,
    $privateKey: String,
    $privateKeyPassphrase: String,
    $profile: String,
  ){
    companyOnboardingSnowflake(
      connectionName: $connectionName
      database: $database
      password: $password
      schema: $schema
      targetName: $targetName
      threads: $threads
      username: $username
      account: $account
      role: $role
      warehouse: $warehouse
      useSso: $useSso
      clientId: $clientId
      clientSecret: $clientSecret
      useKeyPairAuth: $useKeyPairAuth
      privateKey: $privateKey
      privateKeyPassphrase: $privateKeyPassphrase
      profile: $profile
    ){
      ok,
      credentialId
    }
  }
`;

export const dwhCheckRedshift = gql`
  query dwhCheckRedshift(
    $connectionName: String!,
    $database: String!,
    $hostName: String!,
    $password: String,
    $port: String!,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
    $username: String!,
    $credentialId: String,
    $connectionType: RedshiftConnectionType!,
    $profile: String!,
  ){
    dwhCheckRedshift(
      connectionName: $connectionName
      database: $database
      hostName: $hostName
      password: $password
      port: $port
      schema: $schema
      targetName: $targetName
      threads: $threads
      username: $username
      credentialId: $credentialId
      connectionType: $connectionType
      profile: $profile
    ){
      ok
      error
    }
  }
`;

export const setupDwhProductionRedshift = gql`
mutation setupDwhProductionRedshift(
  $connectionName: String!,
  $database: String!,
  $hostName: String!,
  $password: String!,
  $port: String!,
  $schema: String!,
  $targetName: String!,
  $threads: Int!,
  $username: String!,
  $productionEnvName: String!,
  $credentialId: String
  $profile: String
){
  setupDwhProductionRedshift(
    connectionName: $connectionName
    database: $database
    hostName: $hostName
    password: $password
    port: $port
    schema: $schema
    targetName: $targetName
    threads: $threads
    username: $username
    productionEnvName: $productionEnvName,
    credentialId: $credentialId
    profile: $profile
  ){
    ok
  }
}
`;

export const setupDwhRedshift = gql`
mutation setupDwhRedshift(
  $connectionName: String!,
  $connectionType: RedshiftConnectionType!,
  $database: String!,
  $hostName: String!,
  $password: String,
  $port: String!,
  $profile: String!,
  $schema: String!,
  $targetName: String!,
  $threads: Int!,
  $username: String!,
  $credentialId: String
){
  setupDwhRedshift(
    connectionName: $connectionName
    connectionType: $connectionType
    database: $database
    hostName: $hostName
    password: $password
    port: $port
    profile: $profile
    schema: $schema
    targetName: $targetName
    threads: $threads
    username: $username,
    credentialId: $credentialId
  ){
    ok
  }
}
`;

export const companyOnboardingRedshift = gql`
mutation companyOnboardingRedshift(
  $connectionName: String!,
  $connectionType: RedshiftConnectionType!,
  $database: String!,
  $hostName: String!,
  $password: String!,
  $port: String!,
  $profile: String!,
  $schema: String!,
  $targetName: String!,
  $threads: Int!,
  $username: String!,
){
  companyOnboardingRedshift(
    connectionName: $connectionName
    connectionType: $connectionType
    database: $database
    hostName: $hostName
    password: $password
    port: $port
    profile: $profile
    schema: $schema
    targetName: $targetName
    threads: $threads
    username: $username
  ){
    ok
  }
}
`;

export const dwhCheckBigquery = gql`
  query dwhCheckBigquery(
    $connectionName: String!,
    $datasetName: String!,
    $serviceAccountJson: String,
    $targetName: String!,
    $threads: Int!,
    $location: String!,
    $credentialId: String,
    $clientId: String,
    $clientSecret: String,
    $projectId: String,
    $executionProject: String,
    $connectionType: String!,
    $profile: String
    ){
      dwhCheckBigquery(
      connectionName: $connectionName
      datasetName: $datasetName
      serviceAccountJson: $serviceAccountJson
      targetName: $targetName
      threads: $threads,
      location: $location
      credentialId: $credentialId
      clientId: $clientId
      clientSecret: $clientSecret
      projectId: $projectId
      executionProject: $executionProject
      connectionType: $connectionType
      profile: $profile
    ){
      ok
      error
    }
  }
`;

export const setupDwhProductionBigquery = gql`
  mutation setupDwhProductionBigquery(
    $connectionName: String!,
    $datasetName: String!,
    $serviceAccountJson: String!,
    $credentialId: String,
    $threads: Int!,
    $productionEnvName: String!,
    $location: String!,
    $targetName: String!,
    $executionProject: String,
    $connectionType: String!,
    $profile: String
    ){
      setupDwhProductionBigquery(
      connectionName: $connectionName
      datasetName: $datasetName
      serviceAccountJson: $serviceAccountJson
      credentialId: $credentialId
      threads: $threads
      productionEnvName: $productionEnvName
      location: $location
      targetName: $targetName
      executionProject: $executionProject
      connectionType: $connectionType
      profile: $profile
    ){
      ok
      credentialId
    }
  }
`;

export const setupDwhBigquery = gql`
  mutation setupDwhBigquery(
    $connectionName: String!,
    $datasetName: String!,
    $serviceAccountJson: String,
    $targetName: String!,
    $threads: Int!,
    $location: String!,
    $credentialId: String,
    $clientId: String,
    $clientSecret: String,
    $projectId: String,
    $executionProject: String,
    $connectionType: String!,
    $profile: String
    ){
      setupDwhBigquery(
      connectionName: $connectionName
      datasetName: $datasetName
      serviceAccountJson: $serviceAccountJson
      targetName: $targetName
      threads: $threads
      location: $location
      credentialId: $credentialId
      clientId: $clientId
      clientSecret: $clientSecret
      projectId: $projectId
      executionProject: $executionProject
      connectionType: $connectionType
      profile: $profile
    ){
      ok
      credentialId
    }
  }
`;

export const companyOnboardingBigquery = gql`
mutation companyOnboardingBigquery(
  $connectionName: String!,
  $datasetName: String!,
  $serviceAccountJson: String,
  $targetName: String!,
  $threads: Int!,
  $location: String!,
  $executionProject: String,
  $connectionType: String!,
  $clientId: String,
  $clientSecret: String,
  $projectId: String,
  $profile: String,
  ){
    companyOnboardingBigquery(
    connectionName: $connectionName
    datasetName: $datasetName
    serviceAccountJson: $serviceAccountJson
    targetName: $targetName
    threads: $threads
    location: $location
    executionProject: $executionProject
    connectionType: $connectionType
    clientId: $clientId
    clientSecret: $clientSecret
    projectId: $projectId
    profile: $profile
  ){
    ok
    credentialId
  }
}
`;

export const newJoinerDbProfileConfig = gql`
  query newJoinerDbProfileConfig($credentialId: String){
    newJoinerDbProfileConfig(credentialId: $credentialId){
      credentialId
      databaseType
      datasetName
      connectionName
      bigqueryConfig{
        connectionName
        location
        connectionType
        targetName
        projectId
        clientId
        executionProject
      }
      redshiftConfig{
        connectionName
        connectionType
        profile
        hostName
        port
        database
        targetName
      }
      snowflakeConfig{
        connectionName
        account
        role
        database
        warehouse
        useSso
        clientId
        useKeyPairAuth
      }
      fireboltConfig{
        connectionName
        hostName
        targetName
      }
      databricksConfig {
        connectionName
        targetName
        httpPath
        host
        catalog
      }
      duckdbConfig{
        connectionName
        targetName
        connectionType
      }
      extensionConfig{
        connectionName
        targetName
        dbtType
      }
    }
  }
`;

export const alterUserTeamPurpose = gql`
  mutation alterUserTeamPurpose($teamPurpose: String!, $userId: Int!){
    alterUserTeamPurpose(
      teamPurpose: $teamPurpose
      userId: $userId
    ){
      ok
    }
  }
`;

export const alterUserAccountType = gql`
  mutation alterUserAccountType(
    $accountType: String!,
    $userId: Int!,
    ){
    alterUserAccountType(
      accountType: $accountType
      userId: $userId
    ){
      ok
    }
  }
`;

export const listTeam = gql`
  query listTeam {
    listTeam{
      ok
      members{
        __typename
        ... on GQLPendingTeamMember{
          inviteId fullNameIfExists avatarUrl email accountType inviteStatus
        }
        ... on GQLSignedUpTeamMember{
          memberId userId userUid fullName email avatarUrl role team
          accountType lastLogin accountIsDisabled
        }
      }
      active{
        memberId userId userUid fullName email avatarUrl role team
        accountType lastLogin accountIsDisabled
      }
      deleted{
        memberId userId userUid fullName email avatarUrl role team
        accountType lastLogin accountIsDisabled
      }
      pending{
        inviteId fullNameIfExists avatarUrl email accountType inviteStatus
      }
    }
  }
`;

export const projectSettings = gql`
  query projectSettings{
    projectSettings{
      dbtVersion
      dbtPartialParsing
      region
      gitRepositoryUri
      sshPublicKey
      supportedDbtVersions
      gitProvider
      dbtProjectDirUserDefined
    }
  }
`;

export const companyOnboardingSlackCode = gql`
  mutation companyOnboardingSlackCode($code: String!, $redirectUri: String!){
    companyOnboardingSlackCode(
      code: $code
      redirectUri: $redirectUri
    ){
      ok
    }
  }
`;

export const setLookerCredentials = gql`
  mutation setLookerCredentials(
    $baseUrl: String!,
    $clientId: String!,
    $clientSecret: String!,
  ){
    setLookerCredentials(
      baseUrl: $baseUrl,
      clientId: $clientId,
      clientSecret: $clientSecret
    ){
      ok
    }
  }
`;

export const disconnectLooker = gql`
  mutation disconnectLooker{
    disconnectLooker{
      ok
    }
  }
`;

export const disconnectSlack = gql`
  mutation disconnectSlack{
    disconnectSlack{
      ok
    }
  }
`;

export const activateLoom = gql`
  mutation activateLoom{
    activateLoom{
      ok
    }
  }
`;

export const deactivateLoom = gql`
  mutation deactivateLoom{
    deactivateLoom{
      ok
    }
  }
`;

export const organisationIntegrationList = gql`
  query organisationIntegrationList{
    organisationIntegrationList{
      slack
      slackMetadata {
        state
        progressMessage
        progress
      }
      looker
      lookerMetadata {
        state
        progressMessage
        progress
      }
      loom
      loomMetadata{
        state
        progressMessage
        progress
      }
      tableau
      tableauMetadata{
        state
        progressMessage
        progress
      }
      fivetran
      fivetranMetadata{
        state
        progressMessage
        progress
      }
      dbtCloud
      dbtCloudMetadata{
        state
      }
      githubApp
      githubAppMetadata{
        state
      }
      hightouch
      hightouchMetadata{
        state
      }
      powerBi
      powerBiMetadata {
        state
        progressMessage
        progress
        selectedWorkspaces {
          id
          name
        }
      }
      thoughtspot
      thoughtspotMetadata {
          state
          progressMessage
          progress
      }
      sigma
      sigmaMetadata {
          state
          progressMessage
          progress
      }
    }
  }
`;

export const disconnectSigma = gql`
  mutation disconnectSigma {
    disconnectSigma {
      ok
    }
  }
`;

export const connectSigma = gql`
  mutation setSigmaCredentials(
    $clientId: String!,
    $clientSecret: String!,
    $host: String!,
  ) {
    setSigmaCredentials(
      clientId: $clientId
      clientSecret: $clientSecret
      host: $host
    ) {
      ok
      errorMessage
    }
  }
`;

export const getDwhProductionConfigs = gql`
  query getDwhProductionConfigs($productionEnvName: String!) {
    getDwhProductionConfigs(productionEnvName: $productionEnvName) {
      ok
      configurations {
        credentialId
        isDefault
        configuration {
          databaseType
          bigqueryConfig {
            connectionName
            datasetName
            targetName
            threads
            location
            redactedProfile
          }
          bigqueryOrgConfig {
            executionProject
          }
          bigqueryUserConfig {
            datasetName
            threads
          }
          redshiftConfig {
            connectionName
            connectionType
            profile
            hostName
            port
            username
            database
            schema
            targetName
            threads
            redactedProfile
          }
          redshiftUserConfig {
            username
            schema
            threads
            database
            profile
          }
          snowflakeConfig {
            connectionName
            account
            username
            role
            database
            warehouse
            schema
            targetName
            threads
            useSso
            useKeyPairAuth
            redactedProfile
          }
          snowflakeUserConfig {
            username
            schema
            threads
            role
            database
            warehouse
          }
          fireboltConfig {
            connectionName
            hostName
            database
            engineName
            username
            schema
            targetName
            threads
            accountName
            redactedProfile
          }
          databricksConfig {
            connectionName
            targetName
            httpPath
            host
            catalog
            schema
            threads
            redactedProfile
          }
          duckdbConfig {
            connectionType
            connectionName
            targetName
            schema
            threads
            redactedProfile
          }
          duckdbUserConfig {
            schema
            threads
            redactedProfile
          }
        }
      }
    }
  }
`;

export const getOrganisationDbConfig = gql`
  query getOrganisationDbConfigs {
    getOrganisationDbConfigs {
      ok
      incomplete {
        credentialId
        isDefault
        configuration {
          databaseType
          bigqueryOrgConfig {
            connectionName
            targetName
            location
          }
          redshiftOrgConfig {
            connectionName
            connectionType
            profile
            hostName
            port
            targetName
          }
          snowflakeOrgConfig {
            connectionName
            account
            targetName
          }
          fireboltOrgConfig {
            connectionName
            hostName
            targetName
          }
          databricksOrgConfig {
            connectionName
            targetName
            httpPath
            host
            catalog
          }
          duckdbOrgConfig {
            connectionName
            targetName
            connectionType
          }
          extensionOrgConfig {
            connectionName
            targetName
            dbtType
          }
        }
      }
      configurations {
        credentialId
        isDefault
        configuration {
          databaseType
          bigqueryConfig {
            connectionName
            datasetName
            targetName
            threads
            location
            redactedProfile
          }
          bigqueryUserConfig {
            datasetName
            threads
          }
          redshiftConfig {
            connectionName
            connectionType
            profile
            hostName
            port
            username
            database
            schema
            targetName
            threads
            redactedProfile
          }
          redshiftUserConfig {
            username
            schema
            threads
            database
            profile
          }
          snowflakeConfig {
            connectionName
            account
            username
            role
            database
            warehouse
            schema
            targetName
            threads
            clientId
            useSso
            useKeyPairAuth
            redactedProfile
          }
          snowflakeUserConfig {
            username
            schema
            threads
            role
            database
            warehouse
          }
          fireboltConfig {
            connectionName
            hostName
            database
            engineName
            username
            schema
            targetName
            threads
            accountName
            redactedProfile
          }
          databricksConfig {
            connectionName
            targetName
            httpPath
            host
            catalog
            schema
            threads
            redactedProfile
          }
          duckdbConfig {
            connectionType
            connectionName
            targetName
            schema
            threads
            redactedProfile
          }
          duckdbUserConfig {
            schema
            threads
            redactedProfile
          }
          extensionConfig {
            dbtType
            connectionName
            targetName
            schema
            threads
            redactedProfile
          }
        }
      }
    }
  }
`;

export const setupDwhCostSnowflake = gql`
  mutation setupDwhCostSnowflake(
    $account: String!,
    $database: String!,
    $password: String!,
    $username: String!,
  ){
    setupDwhCostSnowflake(
      account: $account
      database: $database
      password: $password
      username: $username
    ){
      ok
      error
    }
  }
`;

export const setupDwhMetadataSnowflake = gql`
  mutation setupDwhMetadataSnowflake(
    $account: String!,
    $database: String!,
    $password: String!,
    $username: String!,
  ){
    setupDwhMetadataSnowflake(
      account: $account
      database: $database
      password: $password
      username: $username
    ){
      ok
    }
  }
`;

export const setupDwhMetadataRedshift = gql`
  mutation setupDwhMetadataRedshift(
    $database: String!
    $hostName: String!
    $password: String!
    $port: String!
    $username: String!
  ){
    setupDwhMetadataRedshift(
      database: $database,
      hostName: $hostName,
      password: $password,
      port: $port,
      username: $username
    ){
      ok
    }
  }
`;

export const setupDwhMetadataBigquery = gql`
  mutation setupDwhMetadataBigquery($serviceAccountJson: String!){
    setupDwhMetadataBigquery(
      serviceAccountJson: $serviceAccountJson
    ){
      ok
    }
  }
`;

export const getDwhCostConfig = gql`
  query getDwhCostConfig {
    getDwhCostConfig {
      databaseType
      bigqueryConfig {
        connectionName
        location
        datasetName
        targetName
        threads
        costGcsBucketName
        costProjectId
      }
      bigqueryOrgConfig {
        connectionName
        location
        costGcsBucketName
        costProjectId
      }
      bigqueryUserConfig {
        datasetName
        threads
      }
      redshiftConfig {
        connectionName
        connectionType
        profile
        hostName
        port
        username
        schema
        targetName
        threads
        database
      }
      redshiftOrgConfig {
        connectionName
        connectionType
        profile
        hostName
        port
        targetName
      }
      redshiftUserConfig {
        username
        schema
        threads
        database
        profile
      }
      snowflakeConfig {
        connectionName
        account
        username
        role
        database
        warehouse
        schema
        targetName
        threads
        useSso
        useKeyPairAuth
      }
      snowflakeOrgConfig {
        connectionName
        account
        useSso
      }
      snowflakeUserConfig {
        username
        role
        database
        warehouse
        schema
        threads
      }
      duckdbConfig {
        connectionType
        connectionName
        schema
        threads
        redactedProfile
      }
      duckdbOrgConfig {
        connectionName
        targetName
        connectionType
      }
      duckdbUserConfig {
        schema
        threads
        redactedProfile
      }
    }
  }
`;

export const getDwhMetadataConfig = gql`
  query getDwhMetadataConfig {
    getDwhMetadataConfig {
      databaseType
      bigqueryConfig {
        connectionName
        location
        datasetName
        targetName
        threads
      }
      bigqueryOrgConfig {
        connectionName
        location
      }
      bigqueryUserConfig {
        datasetName
        threads
      }
      redshiftConfig {
        connectionName
        connectionType
        profile
        hostName
        port
        username
        schema
        targetName
        threads
        database
      }
      redshiftOrgConfig {
        connectionName
        connectionType
        profile
        hostName
        port
        targetName
      }
      redshiftUserConfig {
        username
        schema
        threads
        database
        profile
      }
      snowflakeConfig {
        connectionName
        account
        username
        role
        database
        warehouse
        schema
        targetName
        threads
      }
      snowflakeOrgConfig {
        connectionName
        account
      }
      snowflakeUserConfig {
        username
        role
        database
        warehouse
        schema
        threads
      }
      fireboltConfig{
        connectionName
        hostName
        database
        engineName
        username
        schema
        targetName
        threads
        accountName
      }
      fireboltOrgConfig{
        connectionName
        hostName
        targetName
      }
      duckdbConfig {
        connectionType
        connectionName
        schema
        threads
        redactedProfile
      }
      duckdbOrgConfig {
        connectionName
        targetName
        connectionType
      }
      duckdbUserConfig {
        schema
        threads
        redactedProfile
      }
    }
  }
`;

export const environmentVariables = gql`
  query environmentVariables{
    environmentVariables {
      ok
      variables {
        name
        value
      }
    }
  }
`;

export const setEnvironmentVariable = gql`
  mutation setEnvironmentVariable(
    $name: String!,
    $value: String
  ){
    setEnvironmentVariable(
        name: $name
        value: $value
    ){
        ok
        variables{
            name
            value
        }
    }
  }
`;

export const generateLookerGitSshPublicKey = gql`
  mutation generateLookerGitSshPublicKey{
      generateLookerGitSshPublicKey{
          ok
          sshPublicKey
      }
  }
`;

export const checkLookerGit = gql`
  query checkLookerGit(
    $gitProvider: String!,
    $sshPublicKey: String!,
    $uri: String!,
  ){
      checkLookerGitUri(
          gitProvider: $gitProvider
          sshPublicKey: $sshPublicKey
          uri: $uri
      ){
          ok
      }
  }
`;

export const setLookerGit = gql`
  mutation setLookerGit(
    $gitProvider: String!,
    $sshPublicKey: String!,
    $uri: String!,
  ){
      setLookerGitUri(
        gitProvider: $gitProvider
        sshPublicKey: $sshPublicKey
        uri: $uri
      ){
          ok
      }
  }
`;

export const dwhCheckFirebolt = gql`
  query dwhCheckFirebolt(
    $connectionName: String!,
    $hostName: String!,
    $engineName: String!,
    $username: String!,
    $password: String!,
    $schema: String!,
    $database: String!,
    $targetName: String!,
    $threads: Int!,
    $accountName: String!,
    $credentialId: String,
    $profile: String
  ) {
    dwhCheckFirebolt(
      hostName: $hostName
      connectionName: $connectionName
      database: $database
      engineName: $engineName
      username: $username
      password: $password
      schema: $schema
      targetName: $targetName
      threads: $threads
      accountName: $accountName
      credentialId: $credentialId
      profile: $profile
    ) {
      ok
      error
    }
  }
`;

export const setupDwhFirebolt = gql`
  mutation setupDwhFirebolt(
    $connectionName: String!,
    $hostName: String!,
    $engineName: String!,
    $username: String!,
    $password: String!,
    $schema: String!,
    $database: String!,
    $targetName: String!,
    $threads: Int!,
    $credentialId: String,
    $accountName: String!,
    $profile: String
  ) {
    setupDwhFirebolt(
      hostName: $hostName
      connectionName: $connectionName
      database: $database
      engineName: $engineName
      username: $username
      password: $password
      schema: $schema
      targetName: $targetName
      threads: $threads
      credentialId: $credentialId
      accountName: $accountName
      profile: $profile
    ) {
      ok
    }
  }
`;

export const setupDwhProductionFirebolt = gql`
  mutation setupDwhProductionFirebolt(
    $connectionName: String!,
    $hostName: String!,
    $engineName: String!,
    $username: String!,
    $password: String!,
    $schema: String!,
    $database: String!,
    $targetName: String!,
    $threads: Int!,
    $productionEnvName: String!,
    $credentialId: String,
    $accountName: String!,
    $profile: String
  ) {
    setupDwhProductionFirebolt(
      hostName: $hostName
      connectionName: $connectionName
      database: $database
      engineName: $engineName
      username: $username
      password: $password
      schema: $schema
      targetName: $targetName
      threads: $threads
      productionEnvName: $productionEnvName
      credentialId: $credentialId
      accountName: $accountName
      profile: $profile
    ) {
      ok
    }
  }
`;

export const setupDwhCostFirebolt = gql`
  mutation setupDwhCostFirebolt(
    $hostName: String!,
    $engineName: String!,
    $username: String!,
    $password: String!,
    $database: String!,
    $accountName: String!
  ) {
    setupDwhCostFirebolt(
      hostName: $hostName
      database: $database
      engineName: $engineName
      username: $username
      password: $password
      accountName: $accountName
    ) {
      ok
    }
  }
`;

export const setupDwhMetadataFirebolt = gql`
  mutation setupDwhMetadataFirebolt(
    $accountName: String!,
    $database: String!,
    $engineName: String!,
    $hostName: String!,
    $password: String!,
    $username: String!
  ) {
    setupDwhMetadataFirebolt(
      accountName: $accountName
      database: $database,
      engineName: $engineName,
      hostName: $hostName,
      password: $password,
      username: $username
    ) {
      ok
    }
  }
`;

export const companyOnboardingFirebolt = gql`
  mutation companyOnboardingFirebolt(
    $connectionName: String!,
    $hostName: String!,
    $engineName: String!,
    $username: String!,
    $password: String!,
    $schema: String!,
    $database: String!,
    $targetName: String!,
    $threads: Int!,
    $accountName: String!,
    $profile: String,
  ) {
    companyOnboardingFirebolt(
      hostName: $hostName
      connectionName: $connectionName
      database: $database
      engineName: $engineName
      username: $username
      password: $password
      schema: $schema
      targetName: $targetName
      threads: $threads
      accountName: $accountName
      profile: $profile
    ) {
      ok
    }
  }
`;

export const checkProfileAddFirebolt = gql`
  query checkProfileAddFirebolt(
    $username: String!,
    $password: String!,
    $schema: String!,
    $threads: Int!,
    $credentialId: String!,
    $accountName: String!,
    $database: String!,
    $engineName: String!,
    $profile: String!
  ) {
    checkProfileAddFirebolt(
      username: $username
      password: $password
      schema: $schema
      threads: $threads
      credentialId: $credentialId
      accountName: $accountName
      database: $database
      engineName: $engineName
      profile: $profile
    ) {
      ok
      error
    }
  }
`;

export const profileAddFirebolt = gql`
  mutation profileAddFirebolt(
    $username: String!,
    $password: String!,
    $schema: String!,
    $threads: Int!,
    $credentialId: String!,
    $accountName: String!,
    $database: String!,
    $engineName: String!,
    $profile: String!
  ) {
    profileAddFirebolt(
      username: $username
      password: $password
      schema: $schema
      threads: $threads
      credentialId: $credentialId
      accountName: $accountName
      database: $database
      engineName: $engineName
      profile: $profile
    ) {
      ok
    }
  }
`;

export const deleteProductionDwh = gql`
  mutation deleteProductionDwh(
    $productionEnvName: String!,
    $credentialId: String!
  ){
    deleteProductionDwh(
      productionEnvName: $productionEnvName
      credentialId: $credentialId
    ) {
      ok
    }
  }
`;

export const deleteDevelopmentDwh = gql`
  mutation deleteDevelopmentDwh($credentialId: String!){
    deleteDevelopmentDwh(credentialId: $credentialId) {
      ok
    }
  }
`;

export const setDefaultProductionDwh = gql`
  mutation setDefaultProductionDwh(
    $productionEnvName: String!,
    $credentialId: String!
  ){
    setDefaultProductionDwh(
      productionEnvName: $productionEnvName
      credentialId: $credentialId
    ) {
      ok
    }
  }
`;

export const setDefaultDevelopmentDwh = gql`
  mutation setDefaultDevelopmentDwh($credentialId: String!){
    setDefaultDevelopmentDwh(credentialId: $credentialId) {
      ok
    }
  }
`;

export const removeUser = gql`
  mutation removeUser($userUid: String!) {
    removeUser(userUid: $userUid){ok}
  }
`;

export const reactivateUser = gql`
  mutation reactivateUser($userUid: String!) {
    reactivateUser(userUid: $userUid){ok}
  }
`;

export const currentProfileSetupStage = gql`
  query currentProfileSetupStage{
    currentProfileSetupStage{
      ok
      isGitSetupIncomplete
      isDwhSetupIncomplete
      isBasicSetupIncomplete
    }
  }
`;

export const signUpDomainWhitelist = gql`
  query signUpDomainWhitelist{
    signUpDomainWhitelist{
      ok
      domains
    }
  }
`;

export const setSignUpDomainWhitelist = gql`
  mutation setSignUpDomainWhitelist($domains: [String!]) {
    setSignUpDomainWhitelist(domains: $domains) {
      ok
    }
  }
`;

export const addUserAutomatically = gql`
  mutation addUserAutomatically($workspaceUid: String) {
    addUserAutomatically(workspaceUid: $workspaceUid) {
      ok
      onboardedComplete
      availableWorkspaces {
        name
        uid
      }
      isGitSetupIncomplete
      isDwhSetupIncomplete
      isBasicSetupIncomplete
      noWorkspaceAvailable
      invalidUser
      userInformation {
        ...userInformation
      }
    }
  }
`;

export const getAllClientIds = gql`
  query getAllClientIds {
    getOrganisationDbConfigs {
      ok
      configurations {
        credentialId
        configuration {
          snowflakeConfig {
            clientId
            account
            role
          }
        }
      }
    }
    getDwhProductionConfigs(productionEnvName: "production") {
      ok
      configurations {
        credentialId
        configuration {
          snowflakeConfig {
            clientId
            account
            role
          }
        }
      }
    }
  }
`;

export const snowflakeOauthTokenExpiry = gql`
  query snowflakeOauthTokenExpiry($credentialId: String!) {
    snowflakeOauthTokenExpiry(credentialId: $credentialId) {
      ok
      hasExpired
      expiresEpoch
    }
  }
`;

export const workbenchExecuteQuery = gql`
  mutation workbenchExecuteQuery($worksheetName: String!, $query: String!, $databaseId: String) {
    workbenchExecuteQuery(query: $query, worksheetName: $worksheetName, databaseId: $databaseId) {
      ok
      worksheetName
      success
      message
      resultJson
      isTruncated
    }
  }
`;

export const jobStatus = gql`
  query jobStatus($token: String!) {
    jobStatus(token: $token) {
      ok
      found
      inProgress
      success
      errorMessage
      stage
      progress
    }
  }
`;

export const getScheduleNames = gql`
  query getScheduleNames($offset: Int!, $limit: Int!, $showInactive: Boolean!) {
    getScheduleNames(offset: $offset, limit: $limit, showInactive: $showInactive) {
      ok
      globalError
      globalErrorDetails {
        errorMessageTitle
        humanErrorMessage
        rawErrorMessage
        errorMessageSeverity{
          colorType
          text
        }
        errorMessageDocsUrl
      }
      scheduleNames {
        scheduleTrigger {
          enabled
          scheduleName
          workspaceName
          triggerOn
        }
        name
        lastRunState {
          text
          colorType
        }
        state {
          text
          colorType
        }
        schedule
        owner
        source
        deferredSchedule {
          enabled
          deferredScheduleName
          deferredScheduleNameUuid
          successfulRunOnly
        }
        turboCi {
          enabled
          deferredScheduleName
          deferredScheduleNameUuid
          successfulRunOnly
        }
        commands
        gitBranch
        notifications {
          emailNotifications {
            channel
            events
          }
          slackNotifications {
            channel
            events
          }
          msTeamsNotifications {
            channel
            events
          }
        }
        lastRun
        nextRun
        meta {
          id
          uuid
          scheduleParseId
        }
        suspended
        slaSeconds
      }
      lastParse
      meta {
        totalSize
      }
    }
  }
`;

export const getScheduleName = gql`
  query getScheduleName($offset: Int!, $limit: Int!, $scheduleNameUuid: String!) {
    getScheduleName(
      offset: $offset
      limit: $limit
      scheduleNameUuid: $scheduleNameUuid
    ) {
      ok
      scheduleTrigger {
        enabled
        scheduleName
        workspaceName
        triggerOn
      }
      globalError
      globalErrorDetails {
        errorMessageTitle
        humanErrorMessage
        rawErrorMessage
        errorMessageSeverity{
          colorType
          text
        }
        errorMessageDocsUrl
      }
      error
      errorDetails {
        errorMessageTitle
        humanErrorMessage
        rawErrorMessage
        errorMessageSeverity{
          colorType
          text
        }
        errorMessageDocsUrl
      }
      manualRunScheduleNameID
      canManualRun
      isActive
      state {
        text
        colorType
      }
      name
      schedule
      owner
      source
      deferredSchedule {
        enabled
        deferredScheduleName
        deferredScheduleNameUuid
        successfulRunOnly
      }
      turboCi {
        enabled
        deferredScheduleName
        deferredScheduleNameUuid
        successfulRunOnly
      }
      commands
      gitBranch
      notifications {
        emailNotifications {
          channel
          events
        }
        slackNotifications {
          channel
          events
        }
        msTeamsNotifications {
          channel
          events
        }
      }
      description
      slaSeconds
      info {
        name
        nextRun
        branch
        schedule
        owner
        dbtVersion
        toNotify
      }
      runs {
        parentScheduleRunId
        state{
          colorType
          text
        }
        actor
        actorEmail
        branch
        commit{
          hash
          url
        }
        startDttm
        endDttm
        schedule
        meta {
          id
          uuid
          scheduleNameID
        }
        canCancel
      }
      meta {
        id
        uuid
        totalSize
        scheduleNameEntryId
      }
      suspended
      triggerOnMerge
    }
  }
  `;

export const setScheduleName = gql`
  mutation setScheduleName($scheduleNameInputs: ScheduleNameInputs!, $scheduleNameEntryId: Int) {
    setScheduleName(scheduleName: $scheduleNameInputs, scheduleNameEntryId: $scheduleNameEntryId) {
      ok
      scheduleNameEntryId
      errorLog
      scheduleName {
        name
        schedule
        owner
        source
        deferredSchedule {
          enabled
          deferredScheduleName
          deferredScheduleNameUuid
          successfulRunOnly
        }
        turboCi {
          enabled
          deferredScheduleName
          deferredScheduleNameUuid
          successfulRunOnly
        }
        commands
        gitBranch
        notifications {
          emailNotifications {
            channel
            events
          }
          slackNotifications {
            channel
            events
          }
          msTeamsNotifications {
            channel
            events
          }
        }
        description
        slaSeconds
        scheduleTrigger {
          enabled
          scheduleName
          workspaceName
          triggerOn
        }
      }
    }
  }
`;

export const removeScheduleName = gql`
  mutation removeScheduleName($scheduleNameEntryId: Int!) {
    removeScheduleNameEntry(scheduleNameEntryId: $scheduleNameEntryId){
      ok
      errorLog
    }
  }
`;

export const getArtifactFromPath = gql`
  query BoltArtifactByPath($commandId: Int!, $path: String!) {
    boltArtifactByPath(commandId: $commandId, path: $path) {
        ok
        artifact {
            id
            type
            path
        }
    }
  }
`;

export const getArtifact = gql`
  query BoltArtifact($resourceId: Int!) {
    boltArtifact(resourceId: $resourceId) {
        ok
        artifact {
            id
            type
            path
        }
    }
  }
`;

export const productionEnvironmentVariables = gql`query productionEnvironmentVariables {
  productionEnvironmentVariables {
    ok
    variables {
      name
      value
    }
  }
}
`;

export const setProductionEnvironmentVariable = gql`
  mutation setProductionEnvironmentVariable($name: String!, $value: String!) {
  setProductionEnvironmentVariable(name: $name, value: $value) {
    ok
    variables {
      name
      value
    }
  }
}
`;

export const getScheduleRun = gql`
  query getScheduleRun($scheduleNameUuid: String!, $scheduleRunUuid: String!, $scheduleRunId: Int) {
    getScheduleName(offset: 0, limit: 1, scheduleNameUuid: $scheduleNameUuid) {
      suspended
      slaSeconds
      description
    }
    getScheduleRun(
      scheduleNameUuid: $scheduleNameUuid
      scheduleRunUuid: $scheduleRunUuid
      scheduleRunId: $scheduleRunId
    ) {
      ok
      shouldRequestRunAnalysis
      globalError
      globalErrorDetails {
        errorMessageTitle
        humanErrorMessage
        rawErrorMessage
        errorMessageSeverity{
          colorType
          text
        }
        errorMessageDocsUrl
      }
      error
      errorDetails {
        errorMessageTitle
        humanErrorMessage
        rawErrorMessage
        errorMessageSeverity{
          colorType
          text
        }
        errorMessageDocsUrl
      }
      info {
        name
        state{
          colorType
          text
        }
        actor
        actorEmail
        parentScheduleRunId
        scheduleTrigger {
          enabled
          scheduleName
          workspaceName
          triggerOn
        }
        startDttm
        endDttm
        branch
        commit{
          hash
          url
        }
        owner
        schedule
        toNotify
        commands
        cancelledByEmail
        cancelledByActor
        deferredDetails {
          scheduleNameUuid
          scheduleName
          scheduleRunId
          scheduleRunUuid
          artifactsUsed
        }
      }
      commands {
        state{
          colorType
          text
        }
        command
        startDttm
        endDttm
        schedule
        output
        logScheduleCommandResourceId
        meta {
          id
          uuid
          stdout
          stderr
          returnCode
          scheduleRunID
        }
      }
      freshnessSources {
        countStatus {
          colorType
          text
        }
        sourceName
        tables {
          status {
            colorType
            text
          }
          tableName
          maxLoadedAtDttm
          snapshottedAtDttm
          criteria
          errorString
        }
      }
      meta {
        id
        scheduleNameID
        scheduleNameName
        scheduleNameUUID
        pullRequestId
        pullRequestUrl
      }
    }
  }
`;

export const runSchedule = gql`
  mutation runSchedule($scheduleNameId: Int!, $scheduleNameUuid: String!) {
    runSchedule(
      scheduleNameId: $scheduleNameId
      scheduleNameUuid: $scheduleNameUuid
    ) {
      ok
      errorLog
    }
  }
`;

export const cancelSchedule = gql`
mutation cancelRun($scheduleRunId: Int!) {
  cancelRun(scheduleRunId:$scheduleRunId) {
    ok
    errorLog
  }
}
`;

export const getCommandArtifacts = gql`
query getCommandArtifacts($scheduleCommandId: Int!, $artifactType: ScheduleRunCommandArtifactType!) {
  getCommandArtifacts(artifactType: $artifactType, scheduleCommandId: $scheduleCommandId) {
    ok
    artifacts {
      id
      path
    }
  }
}
`;

export const getPublicResourceUrl = gql`
query getPublicResourceUrl($scheduleCommandResourceId: Int!) {
  getPublicResourceUrl(scheduleCommandResourceId: $scheduleCommandResourceId) {
    ok
    url
  }
}`;

export const setTableauCredentials = gql`
mutation setTableauCredentials(
  $url: String!,
  $patName: String!,
  $patSecret: String!,
){
  setTableauCredentials(
      url: $url
      patName: $patName
      patSecret: $patSecret
  ){
      ok
  }
}
`;

export const disconnectTableau = gql`
mutation disconnectTableau {
  disconnectTableau {
    ok
  }
}
`;

export const paywallUpgradeRequest = gql`
  mutation upgradeRequest($requestType:String!, $feature: String!){
    upgradeRequest(requestType: $requestType, feature: $feature){
      ok
    }
  }
`;

export const setFivetranCredentials = gql`
  mutation setFivetranCredentials($apiKey: String!, $apiSecret: String!){
    setFivetranCredentials(key: $apiKey, secret: $apiSecret){
      ok
    }
  }
`;

export const checkFivetranCredentials = gql`
  query checkFivetranCredentials($apiKey: String!, $apiSecret: String!) {
    checkFivetranCredentials(
      apiKey: $apiKey
      apiSecret: $apiSecret
    ) {
      ok
    }
  }
`;

export const disconnectFivetran = gql`
  mutation disconnectFivetran {
    disconnectFivetran {
      ok
    }
  }
`;

export const setDbtCloudCredentials = gql`
  mutation setDbtCloudCredentials($hostName: String!, $serviceAccountToken: String!){
    setDbtCloudCredentials(hostName: $hostName, serviceAccountToken: $serviceAccountToken){
      ok
      numberOfJobs
      pullRequestUrl
      error
    }
  }
`;

export const disconnectDbtCloud = gql`
  mutation disconnectDbtCloud{
    disconnectDbtCloud{
      ok
    }
  }
`;

export const setHightouchApiKey = gql`
  mutation setHightouchApiKey($apiKey: String!){
    setHightouchApiKey(apiKey: $apiKey){
      ok
      error
    }
  }
`;

export const removeHightouchApiKey = gql`
  mutation removeHightouchApiKey{
    removeHightouchApiKey{
      ok
    }
  }
`;

export const getGithubAppUrl = gql`
  query getGithubAppUrl{
    getGithubAppUrl{
      ok
      url
      userOauthUrl
      shouldRequestUserOauth
    }
  }
`;

export const checkProfileAddDatabricks = gql`
  query checkProfileAddDatabricks(
        $schema: String!,
        $credentialId: String!,
        $threads: Int!,
        $token: String,
        $profile: String!
    ){
      checkProfileAddDatabricks(
        schema: $schema
        credentialId: $credentialId
        threads: $threads
        token: $token
        profile: $profile
      ){ok error}
    }
`;

export const profileAddDatabricks = gql`
  mutation profileAddDatabricks(
      $schema: String!,
      $credentialId: String!,
      $threads: Int!,
      $token: String,
      $profile: String!
  ){
    profileAddDatabricks(
        schema: $schema
        credentialId: $credentialId
        threads: $threads
        token: $token
        profile: $profile
    ){
      ok,
      credentialId,
    }
  }
`;

export const dwhCheckDatabricks = gql`
  query dwhCheckDatabricks(
      $credentialId: String
      $connectionName: String!
      $httpPath: String!
      $host: String!
      $catalog: String!
      $token: String
      $schema: String!
      $targetName: String!
      $threads: Int!
      $profile: String
    ){
      dwhCheckDatabricks(
        credentialId: $credentialId
        connectionName: $connectionName
        httpPath: $httpPath
        host: $host
        catalog: $catalog
        token: $token
        schema: $schema
        targetName: $targetName
        threads: $threads
        profile: $profile
      ){
        ok
        error
      }
    }
`;

export const setupDwhProductionDatabricks = gql`
  mutation setupDwhProductionDatabricks(
    $catalog: String!
    $connectionName: String!
    $credentialId: String
    $host: String!
    $httpPath: String!
    $productionEnvName: String!
    $schema: String!
    $targetName: String!
    $threads: Int!
    $token: String!
    $profile: String
  ){
    setupDwhProductionDatabricks(
      catalog: $catalog
      connectionName: $connectionName
      credentialId: $credentialId
      host: $host
      httpPath: $httpPath
      productionEnvName: $productionEnvName
      schema: $schema
      targetName: $targetName
      threads: $threads
      token: $token
      profile: $profile
    ){
      ok,
      credentialId
    }
  }
`;

export const setupDwhDatabricks = gql`
  mutation setupDwhDatabricks(
    $catalog: String!
    $connectionName: String!
    $credentialId: String
    $host: String!
    $httpPath: String!
    $schema: String!
    $targetName: String!
    $threads: Int!
    $token: String
    $profile: String
  ){
    setupDwhDatabricks(
      catalog: $catalog
      connectionName: $connectionName
      credentialId: $credentialId
      host: $host
      httpPath: $httpPath
      schema: $schema
      targetName: $targetName
      threads: $threads
      token: $token
      profile: $profile
    ){
      ok,
      credentialId
    }
  }
`;

export const companyOnboardingDatabricks = gql`
  mutation companyOnboardingDatabricks(
    $catalog: String!
    $connectionName: String!
    $host: String!
    $httpPath: String!
    $schema: String!
    $targetName: String!
    $threads: Int!
    $token: String!
    $profile: String
  ){
    companyOnboardingDatabricks(
      catalog: $catalog
      connectionName: $connectionName
      host: $host
      httpPath: $httpPath
      schema: $schema
      targetName: $targetName
      threads: $threads
      token: $token
      profile: $profile
    ){
      ok,
      credentialId
    }
  }
`;

export const listWorkspaces = gql`
  query listWorkspaces {
    listWorkspaces{
      ok
      workspaces{
        uid
        name
        isDefault
        isSetupComplete
        canSetupBeCompleted
      }
      workspacesAvailableForAutoJoin {
        uid
        name
        isDefault
        isSetupComplete
        canSetupBeCompleted
      }
      maxWorkspaceCount
      canCreateWorkspace
    }
  }
`;

export const createWorkspace = gql`
  mutation createWorkspace(
    $workspaceName: String!,
    $isAutoJoinEnabled: Boolean,
  ) {
    createWorkspace(
      name: $workspaceName
      isAutoJoinEnabled: $isAutoJoinEnabled
    ) {
      ok
      uid
    }
  }
`;

export const setCurrentWorkspace = gql`
  mutation setCurrentWorkspace($uid: String!) {
    setCurrentWorkspace(uid: $uid) {
      ok
    }
  }
`;

export const githubSendLinkEmail = gql`
  mutation githubSendLinkEmail($email: String!) {
    githubSendLinkEmail(email: $email) {
      ok
    }
  }
`;

export const githubLinkUserId = gql`
  mutation githubLinkUserId(
    $githubCode: String! 
    $uid: String!
  ) {
    githubLinkUserId(githubCode: $githubCode, uid: $uid) {
      ok
    }
  }
`;

export const getSvixPortal = gql`
  query getSvixPortal {
    getSvixPortal{
      ok
      url
    }
  }
`;

export const setPowerBiCredentials = gql`
  mutation setPowerBiCredentials(
    $clientId: String!
    $clientSecret: String!
    $tenantId: String!
  ){
      setPowerBiCredentials(
        clientId: $clientId
        clientSecret: $clientSecret
        tenantId: $tenantId
      ){
        ok
        workspaces {
          id
          name
        }
      }
    }
`;

export const setPowerBiWorkspaces = gql`
  mutation setPowerBiWorkspaces(
    $workspaceIds: [String!]!
  ){
      setPowerBiWorkspaces(
        workspaceIds: $workspaceIds
      ){
        ok
      }
    }
`;

export const disconnectPowerBi = gql`
  mutation disconnectPowerBi {
    disconnectPowerBi {
      ok
    }
  }
`;

export const checkProfileAddDuckdb = gql`
  query checkProfileAddDuckdb(
    $credentialId: String!,
    $schema: String!,
    $threads: Int!,
    $profile: String!
  ){
    checkProfileAddDuckdb(
      credentialId: $credentialId
      schema: $schema
      threads: $threads
      profile: $profile
    ){
      ok
      error
    }
  }
`;

export const profileAddDuckdb = gql`
  mutation profileAddDuckdb(
    $credentialId: String!,
    $schema: String!,
    $threads: Int!,
    $profile: String!,
    $token: String,
  ){
    profileAddDuckdb(
      credentialId: $credentialId
      schema: $schema
      threads: $threads
      token: $token
      profile: $profile
    ){
      ok,
      credentialId,
    }
  }
`;

export const dwhCheckDuckdb = gql`
  query dwhCheckDuckdb(
    $credentialId: String,
    $connectionName: String!,
    $profile: String!,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
    $connectionType: DuckdbConnectionType,
  ){
    dwhCheckDuckdb(
      credentialId: $credentialId
      connectionName: $connectionName
      profile: $profile
      schema: $schema
      targetName: $targetName
      threads: $threads
      connectionType: $connectionType
    ){
      ok
      error
    }
  }
`;

export const setupDwhProductionDuckdb = gql`
  mutation setupDwhProductionDuckdb(
    $connectionName: String!,
    $credentialId: String,
    $productionEnvName: String!,
    $profile: String!,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
    $connectionType: DuckdbConnectionType,
  ){
    setupDwhProductionDuckdb(
      connectionName: $connectionName
      credentialId: $credentialId
      productionEnvName: $productionEnvName
      profile: $profile
      schema: $schema
      targetName: $targetName
      threads: $threads
      connectionType: $connectionType
    ){
      ok
    }
  }
`;

export const setupDwhDuckdb = gql`
  mutation setupDwhDuckdb(
    $connectionName: String!,
    $credentialId: String,
    $profile: String!,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
    $connectionType: DuckdbConnectionType,
  ){
    setupDwhDuckdb(
      connectionName: $connectionName
      credentialId: $credentialId
      profile: $profile
      schema: $schema
      targetName: $targetName
      threads: $threads
      connectionType: $connectionType
    ){
      ok,
      credentialId
    }
  }
`;

export const companyOnboardingDuckdb = gql`
  mutation companyOnboardingDuckdb(
    $connectionName: String!,
    $profile: String!,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
    $connectionType: DuckdbConnectionType,
  ){
    companyOnboardingDuckdb(
      connectionName: $connectionName
      profile: $profile
      schema: $schema
      targetName: $targetName
      threads: $threads
      connectionType: $connectionType
    ){
      ok,
      credentialId
    }
  }
`;

export const setThoughtspotcredentials = gql`
  mutation setThoughtspotCredentials(
    $host: String!,
    $password: String!,
    $username: String!,
  ){
    setThoughtspotCredentials(
      host: $host
      password: $password
      username: $username
    ) {
      ok
      errorMessage
    }
  }
`;

export const disconnectThoughtSpot = gql`
  mutation disconnectThoughtspot {
    disconnectThoughtspot {
      ok
    }
  }
`;

export const listThoughtSpotSyncModels = gql`
  query listThoughtspotSyncModels {
    listThoughtspotSyncModels {
      ok
      errorMessage
      boltRunId
      models {
        modelFolderName
        modelFolderPath
        modelName
        databaseName
        schemaName
      }
    }
  }
`;

export const syncThoughtspotDbtModels = gql`
  mutation syncThoughtspotDbtModels(
    $connectionName: String!,
    $databaseName: String!
    $models: [SelectedThoughtSpotDbtModel!]!,
  ){
    syncThoughtspotDbtModels(
      connectionName: $connectionName
      databaseName: $databaseName
      models: $models
    ) {
      ok
      errorMessage
      worksheetsImported
      thoughtspotUrl
    }
  }
`;

export const getBoltRunStatuses = gql`
  query boltRunStatuses {
    boltRunStatuses {
      ok
      statuses{
        colorType
        text
      }
    }
  }
`;

export const createStripePaymentIntent = gql`
  mutation createStripePaymentIntent {
    createStripeSetupIntent {
      ok
      clientSecret
    }
  }
`;

export const listApiKeys = gql`
  query listApiKeys {
    listApiKeys {
      ok
      apiKeys {
        name
        apiKey
        expiresAtDttm
        lastUsedDttm
        createdAtDttm
        capabilities
      }
    }
  }
`;

export const generateApiKey = gql`
  mutation generateApiKey(
    $capabilityGroups: [String!]!,
    $expiresAt: Int,
    $name: String,
  ){
    generateApiKey(
      capabilityGroups: $capabilityGroups
      expiresAt: $expiresAt
      name: $name
    ) {
      ok
      key
      endpoint
      secret
    }
  }
`;

export const deactivateApiKey = gql`
  mutation deactivateApiKey(
    $key: String!,
  ){
    deactivateApiKey(
      key: $key
    ) {
      ok
    }
  }
`;

export const listApiKeysCapabilityGroups = gql`
  query listApiKeysCapabilityGroups {
    listApiKeysCapabilityGroups {
      ok
      capabilityGroups {
        id
        name
        description
        requiresUpgrade
      }
    }
  }
`;

export const getCustomerSubscriptions = gql`
  query getCustomerSubscriptions {
    customerSubscriptions {
        ok
        customer {
            name
            address
            email
        }
        hasSubscription
        subscriptions {
            planName
            planGroup
            status
            currentPeriodEnd
            interval
            trialEnd
        }
        accountStatus
    }
  }
`;

export const getWarehouseConnectionStatuses = gql`
  query getWarehouseConnectionStatuses {
    getOrganisationDbConfigs {
      ok
      configurations {
        isDefault
        configuration {
          databaseType
        }
      }
      incomplete {
        isDefault
        configuration {
          databaseType
        }
      }
    }

    getDwhProductionConfigs(productionEnvName: "production") {
      ok
      configurations {
        credentialId
        configuration {
          databaseType
        }
      }
    }

    getDwhCostConfig {
      databaseType
    }
  }
`;

export const getProductsAndPrices = gql`
  query getProductsAndPrices {
    productsAndPrices {
      ok
      products {
        stripeId
        name
        planName
        planGroup
        description
        features
        demoVideoUrl
        bookACallUrl
        prices {
          stripeId
          amount
          recurringInterval
        }
        pricedPerUser
        numChargeableUsers
        isAddOn
      }
    }
    customerSubscriptions {
      ok
      customer {
        name
        address
        email
      }
      hasSubscription
      subscriptions {
        planName
        planGroup
        status
        currentPeriodEnd
        interval
        trialEnd
      }
      accountStatus
    }
  }
`;

export const setupDwhCostBigquery = gql`
  mutation setupDwhCostBigquery(
    $costGcsBucketName: String!,
    $location: String!,
    $costProjectId: String!,
    $serviceAccountJson: String!,
  ){
    setupDwhCostBigquery(
      costGcsBucketName: $costGcsBucketName,
      location: $location,
      costProjectId: $costProjectId,
      serviceAccountJson: $serviceAccountJson,
    ){
      ok
    }
  }
`;

export const getAllScheduleNamesWithWorkspace = gql`
query getAllScheduleNamesWithWorkspace {
  getAllScheduleNamesWithWorkspace {
    ok
    scheduleNames {
      scheduleName
      workspaceName
    }
  }
}`;

export const listActiveSnowflakeWarehouseOptimizations = gql`
  query ListActiveSnowflakeWarehouseOptimizations {
    listActiveSnowflakeWarehouseOptimizations {
      optimizerAgentWarehouses
      autoscalerAgentWarehouses
    }
  }
`;

export const snowflakeApplyWarehouseOptimization = gql`
mutation snowflakeApplyWarehouseOptimization ($warehouseName: String!) {
  snowflakeApplyWarehouseOptimization (warehouseName: $warehouseName) {
    ok
  }
}
`;

export const snowflakeRemoveWarehouseOptimization = gql`
mutation snowflakeRemoveWarehouseOptimization ($warehouseName: String!) {
  snowflakeRemoveWarehouseOptimization (warehouseName: $warehouseName) {
    ok
  }
}
`;

export const snowflakeApplyCostAgent = gql`
mutation snowflakeApplyCostAgent ($warehouseName: String!) {
  snowflakeApplyCostAgent (warehouseName: $warehouseName) {
    ok
  }
}
`;

export const snowflakeRemoveCostAgent = gql`
mutation snowflakeRemoveCostAgent ($warehouseName: String!) {
  snowflakeRemoveCostAgent (warehouseName: $warehouseName) {
    ok
  }
}
`;

export const trackTelemetryEvent = gql`
mutation trackTelemetryEvent($event: String!, $attributes: [TelemetryAttribute!]!) {
  trackTelemetryEvent(
    event: $event
    attributes: $attributes
  ) {
    ok
  }
}
`;

export const generateCubeEndpointAndToken = gql`
  mutation generateCubeEndpointAndToken {
    generateCubeToken {
      ok
      endpoint
      token
    }
  }
`;

export const getMsTeamsWebhooks = gql`
  query getMsTeamsWebhooks {
    getMsTeamsWebhooks {
      webhookNames
    }
  }
`;

export const addMsTeamsWebhook = gql`
  mutation addMsTeamsWebhooks($name: String!, $webhook: String!) {
    addMsTeamsWebhook(name: $name, webhook: $webhook) {
      ok
    }
  }
`;

export const getMSTeamsWebhook = gql`
  query getMsTeamsWebhook($name: String!) {
    getMsTeamsWebhook(name: $name) {
      webhook
    }
  }
`;

export const removeMsTeamsWebhook = gql`
  mutation removeMsTeamsWebhook($name: String!) {
    removeMsTeamsWebhook(name: $name) {
      ok
    }
  }
`;

export const getAlertNotifications = gql`
  query getAlertNotifications($alertChannel: KnockAlertChannelID!) {
    getAlertNotifications(alertChannel: $alertChannel) {
      ok
      slackUserIds
      slackChannelIds
      msTeamsChannelNames
      emailsEnabled
    }
  }
`;

export const setAlertNotifications = gql`
  mutation setAlertNotifications(
    $alertChannel: KnockAlertChannelID!,
    $msTeamsChannelNames: [String!],
    $slackChannelIds: [String!],
    $slackUserIds: [String!],
    $emailsEnabled: Boolean,
  ) {
    setAlertNotifications(
      alertChannel: $alertChannel,
      msTeamsChannelNames: $msTeamsChannelNames,
      slackChannelIds: $slackChannelIds,
      slackUserIds: $slackUserIds,
      emailsEnabled: $emailsEnabled,
    ) {
      ok
    }
  }
`;

export const removeAlertNotifications = gql`
  mutation removeAlertNotifications(
    $alertChannel: KnockAlertChannelID!,
  ) {
    removeAlertNotifications(
      alertChannel: $alertChannel,
    ) {
      ok
    }
  }
`;

export const dwhCheckExtension = gql`
  query dwhCheckExtension(
    $credentialId: String,
    $connectionName: String!,
    $profile: String!,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
    $dbtType: String!,
  ) {
    dwhCheckExtension(
      credentialId: $credentialId,
      connectionName: $connectionName,
      profile: $profile,
      schema: $schema,
      targetName: $targetName,
      threads: $threads,
      dbtType: $dbtType,
    ) {
      ok,
      error
    }
  }
`;

export const checkProfileAddExtension = gql`
  query checkProfileAddExtension(
    $credentialId: String!,
    $profile: String!,
    $schema: String!,
    $threads: Int!,
  ) {
    checkProfileAddExtension(
      credentialId: $credentialId,
      profile: $profile,
      schema: $schema,
      threads: $threads,
    ) {
      ok,
      error
    }
  }
`;

export const companyOnboardingExtension = gql`
  mutation companyOnboardingExtension(
    $connectionName: String!,
    $dbtType: String!,
    $profile: String!,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
  ) {
    companyOnboardingExtension(
      connectionName: $connectionName,
      dbtType: $dbtType,
      profile: $profile,
      schema: $schema,
      targetName: $targetName,
      threads: $threads,
    ) {
      ok,
      credentialId
    }
  }
`;

export const setupDwhExtension = gql`
  mutation setupDwhExtension(
    $connectionName: String!,
    $credentialId: String,
    $dbtType: String!,
    $profile: String!,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
  ) {
    setupDwhExtension(
      connectionName: $connectionName,
      credentialId: $credentialId,
      dbtType: $dbtType,
      profile: $profile,
      schema: $schema,
      targetName: $targetName,
      threads: $threads,
    ) {
      ok,
      credentialId
    }
  }
`;

export const setupDwhProductionExtension = gql`
  mutation setupDwhProductionExtension(
    $connectionName: String!,
    $credentialId: String,
    $dbtType: String!,
    $productionEnvName: String!,
    $profile: String!,
    $schema: String!,
    $targetName: String!,
    $threads: Int!,
  ) {
    setupDwhProductionExtension(
      connectionName: $connectionName,
      credentialId: $credentialId,
      dbtType: $dbtType,
      productionEnvName: $productionEnvName,
      profile: $profile,
      schema: $schema,
      targetName: $targetName,
      threads: $threads,
    ) {
      ok,
      credentialId
    }
  }
`;

export const profileAddExtension = gql`
  mutation profileAddExtension(
    $credentialId: String!,
    $profile: String!,
    $schema: String!,
    $threads: Int!,
    $token: String,
  ) {
    profileAddExtension(
      credentialId: $credentialId,
      profile: $profile,
      schema: $schema,
      threads: $threads,
      token: $token
    ) {
      ok,
      credentialId
    }
  }
`;

/* eslint-disable no-nested-ternary */
import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
} from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import {
  costsProdWarehouseOptions,
  costsWarehousesButtons,
  devProdWarehouseOptions,
  useGetDevProdWarehousesButtons,
  WarehouseEnv,
} from '../../Common/Warehouses/utils';
import ConnectionFactory from './ConnectionFactory';
import useGetConnectionsConfig, { ConfigurationsContext } from './hooks/useGetConnectionsConfig';
import PageTitle, { PageTitles } from '../../PageTitle';
import { AccountSettingsHelp } from '../../Common/AppHelp/help';
import { GqlParadimeAccountType } from '../../../client/generated/service';
import {
  userHasAccountSettingsConnectionsCostAccess, userHasAccountSettingsConnectionsCostUpdateAccess,
  userHasAccountSettingsConnectionsDevAccess,
  userHasAccountSettingsConnectionsDevUpdateAccess,
  userHasAccountSettingsConnectionsProdAccess,
  userHasAccountSettingsConnectionsProdUpdateAccess,
} from '../../../utilis/PermissionsService';

export const EditableContext = createContext({
  isEditable: false,
  setIsEditable: ((() => { }) as Dispatch<SetStateAction<boolean>>),
});

export interface ConnectionsProps {
  accessLevel: GqlParadimeAccountType
}

const Connections: FC<ConnectionsProps> = ({ accessLevel }) => {
  const {
    developmentConfigData,
    getDevelopmentConfig,
    getProductionConfig,
    productionConfigData,
    costsConfigData,
    getCostsConfig,
  } = useGetConnectionsConfig();

  const devProdWarehouseButtonsList = useGetDevProdWarehousesButtons();

  return (
    <>
      <PageTitle title={PageTitles.CONNECTIONS} />
      <ConfigurationsContext.Provider value={{
        configurations: productionConfigData,
      }}
      >
        <AutoLayout
          direction="vertical"
          padding="none"
          verticalGap="very-dense"
          distribution="packed"
          alignment="top-left"
          style={{ rowGap: '64px' }}
        >

          {userHasAccountSettingsConnectionsDevAccess(accessLevel) && (
            <ConnectionFactory
              title="Code IDE"
              help={{
                text: 'Learn More',
                onClick: () => (
                  window.CommandBar.openHelpHub(
                    { articleId: AccountSettingsHelp.WORKSPACE_DEV_CONNECTIONS },
                  )
                ),
              }}
              warehouseOptions={devProdWarehouseOptions}
              bigButtons={devProdWarehouseButtonsList}
              env={WarehouseEnv.DEV}
              completeConfigs={
                        developmentConfigData?.getOrganisationDbConfigs.configurations
                      }
              incompleteConfigs={
                        developmentConfigData?.getOrganisationDbConfigs.incomplete
                      }
              getConfig={getDevelopmentConfig}
              canEdit={userHasAccountSettingsConnectionsDevUpdateAccess(accessLevel)}
              accessLevel={accessLevel}
            />
          )}

          {userHasAccountSettingsConnectionsProdAccess(accessLevel) && (
            <ConnectionFactory
              title="Scheduler"
              help={{
                text: 'Learn More',
                onClick: () => (
                  window.CommandBar.openHelpHub(
                    { articleId: AccountSettingsHelp.WORKSPACE_PROD_CONNECTIONS },
                  )
                ),
              }}
              warehouseOptions={devProdWarehouseOptions}
              bigButtons={devProdWarehouseButtonsList}
              env={WarehouseEnv.PROD}
              completeConfigs={
                        productionConfigData?.getDwhProductionConfigs.configurations
                      }
              getConfig={getProductionConfig}
              canEdit={userHasAccountSettingsConnectionsProdUpdateAccess(accessLevel)}
              accessLevel={accessLevel}
              adminFirst
            />
          )}

          {userHasAccountSettingsConnectionsCostAccess(accessLevel) && (
            <ConnectionFactory
              title="Radar"
              help={{
                text: 'Learn More',
                onClick: () => (
                  window.CommandBar.openHelpHub(
                    { articleId: AccountSettingsHelp.WORKSPACE_COST_CONNECTIONS },
                  )
                ),
              }}
              warehouseOptions={costsProdWarehouseOptions}
              bigButtons={costsWarehousesButtons}
              env={WarehouseEnv.COSTS} // @ts-ignore
              completeConfigs={(!costsConfigData || costsConfigData?.getDwhCostConfig.databaseType === 'unknown')
                ? []
                : [{
                  configuration: costsConfigData?.getDwhCostConfig,
                }]}
              getConfig={getCostsConfig}
              canEdit={userHasAccountSettingsConnectionsCostUpdateAccess(accessLevel)}
              accessLevel={accessLevel}
              adminFirst
            />
          )}
        </AutoLayout>
      </ConfigurationsContext.Provider>
    </>
  );
};

export default Connections;
